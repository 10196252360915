@import '../../variables';

.contactContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    text-align: left;
}

.titleBar {
    grid-column: 1/2;
    text-align: left;
    padding: 1.3rem 1rem;
    border-bottom: 1px solid #ccc;
    border-image: $hline;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.contactForm {
    form {
        display: flex;
        flex-direction: column;
        input, textarea {
            padding: 1rem;
            margin: 1rem;
            border: 2px solid $gray1;
            color: black;
            font-family: 'Poppins', sans-serif;
        }
        
    }
}

.button {
    cursor: pointer;
}