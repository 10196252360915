@import "../../../variables";

.workPiece {
    border-bottom: 1px solid #ccc;
    border-image: $hline;
    margin-bottom: 1rem;
    text-align: center;

    img {
        max-width: 10rem;
        box-shadow: 3px 3px 5px $gray4;

        &.svg {
            width: 1.5rem;
            border: none;
            color: red;
        }
    }

    h3 {
        text-align: center;
    }

    h4 {
        text-align: center;
        font-weight: normal;
        text-emphasis: none;
        padding-bottom: 1rem;
    }
}
