@import '../../../variables';

.BlogPost {
    background-color: white;
    //border: 1px solid $gray1;
    box-shadow: -5px 0px 4px -3px $gray3;
    border-radius: 5px;
    width: 100%;
    margin: 1rem;
    padding: 0.5rem;
}