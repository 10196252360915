@import "../../variables";

.aboutContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    text-align: left;
    height: 100%;
}

.element1 {
    grid-column: 1/3;
    text-align: left;
    padding: 1.3rem 1rem;
    border-bottom: 1px solid #ccc;
    border-image: linear-gradient(90deg, transparent, #CCC, transparent) 1;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;

    p::first-letter {
        color: orangered;
    }
}

.element2 {
    padding: 0.5rem;
    border-right: 1px solid #ccc;
    border-image: linear-gradient(#CCC, transparent) 1;
}

.element3 {
    padding: 0.5rem;

    li {
        border-bottom: 1px solid #ccc;
        border-image: linear-gradient(90deg, transparent, #ccc, transparent) 1;
        padding: 1rem 0;
    }

    li:last-of-type {
        border-bottom: none;
    }
}

.badge {
    background-color: $main-color1;
    color: white;
    border-radius: 2px;
    font-size: 0.8rem;
    margin: 0.3rem;

}