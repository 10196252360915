@import '../../variables';

.MainContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
}

// .MenuContainer {
//     position: fixed;
//     top: 0;
//     left: 8rem;
// }


@media (min-width: $landscape-width) {

    .MainContainer {
        display: flex;
        flex-direction: row;
    }

    // .MenuContainer {
    // }

    .TitlePageContainer {
        // position: fixed;
        // top: 0;
        // left: 8rem;
        margin-right: 0;
        padding-right: 0;
        z-index: 10;
    }

    .ContentContainer {
        margin-left: -2px;
        border-radius: 4px;
        width: 600px;
        max-height: 450px;

        // overflow-y: auto;
        overflow: hidden;

        &:hover {
            overflow-y: overlay;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    ::-webkit-scrollbar-thumb {
        background: $main-color1; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: $main-color1; 
    }

}