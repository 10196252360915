@import '../../../variables';

.menuItem {
    border-left: 1px solid #ccc ;
    border-image: linear-gradient(transparent, #ccc , transparent) 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    margin:0;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: none;
    
    .menuIcon {
        font-size: 1.4rem;
        padding-bottom: 0.5rem;
    }

    p {
        padding: 0;
        color: inherit;
        text-decoration: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    @media (min-width: $landscape-width) {
        border-left: none;
        border-bottom: 1px solid $gray1;
        border-image: $hline;
    }
}

.activeIcon {
    color: $main-color1;
}

.inactiveIcon {
    color: $gray4;
}