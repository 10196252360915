@import "../../variables";

.blogContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    text-align: left;
    vertical-align: top;
}

.element1 {
    grid-column: 1/2;
    text-align: left;
    padding: 1.3rem 1rem;
    border-bottom: 1px solid #ccc;
    border-image: $hline;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.element2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}