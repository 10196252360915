@import '../../variables';

.buttons {
    margin-bottom: 0.3rem;
    button {
        font-family: Poppins 400, sans-serif;
        font-weight: bold;
        color: $gray3;
        width: 50%;
        height: 2.5rem;
        text-transform: uppercase;
        font-size: 0.8rem;
        border: none;
        border-top: 1px solid $gray1;
        background-color: inherit;
        letter-spacing: 0.05rem;
        border-image: linear-gradient(120deg, $gray1 , transparent) 1;
        cursor: pointer;
    }
    button:first-of-type {
        border-right: 1px solid $gray1;
        border-image: linear-gradient(-60deg, $gray1 , transparent) 1;
    }
}

#background {
    position: static;
    width: 100%;
    height: 15rem;
    background: url(../../assets/backgrounds/background.jpg);
    background-size: cover;
}

.bottomEdge {
    display: flex;
    flex-direction: row;
    width: 100%;
}



#triangleBottomleft {
    width: 0;
    height: 0;
    border-bottom: 30px solid white;
    border-right: 200px solid transparent;
    position: relative;
    bottom: -5rem;
}

#triangleBottomright {
    width: 0;
    height: 0;
    border-bottom: 30px solid white;
    border-left: 200px solid transparent;
    position: relative;
    bottom: -5rem;
}

#profilePhoto {
    width: 8rem;
    height: 8rem;
    border-radius: 4rem;
    border: 3px solid white;
    box-shadow: -10px 10px 2px $box-shadow-color;
    position: relative;
    top: 9.5rem;
    z-index: 1;
}

#name {
    font-size: 1.7rem;
    color: $gray4;
    margin-top: 4rem;
}

#title {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 0.9rem;
    color: $main-color1;
}

.socialMedia {
    color: $gray4;
    width: 10rem;
    display: flex;
    justify-content: space-evenly;
    border: none;
    margin: auto;
    padding: 1.3rem;
}
