@import "_variables.scss";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}




body {
    background: url(./assets/backgrounds/background.jpg) no-repeat center fixed;
    background-size: cover;
    font-family: 'Poppins', sans-serif;
}

.App {
    text-align: left;
    box-sizing: border-box;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    @media(min-width: $landscape-width) {
        // flex-direction: row;
        // justify-content: flex-start;
        margin: auto;
        width: 80%;
        max-width: 1200px;
        padding: 70px 0;


        .hidden-card {
            display: none;
        }


    }
  }



h1 {
    font-size: 1.4rem;
    color: $gray4;
    position: relative;
    left: -1rem;
}

h2 {
    font-size: 1rem;
    text-transform: uppercase;
    color: $main-color1;
    border-bottom: 1px solid $gray1;
    border-image: $hline;
    padding: 1rem;
}

h3 {
    font-size: 1rem;
    font-weight: bold;
    color: $gray4;
    letter-spacing: 0.03rem;
    padding: 0.2rem 0.5rem;
}

h4 {
    color: $gray2;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    padding: 0 0.5rem;

}

p {
    line-height: 1.4rem;
    font-size: 0.9rem;
    color: $gray4;
    padding: 0.5rem;
}



.main-container {
    background-color: white;
    border: 1px solid $gray4;
    text-align: center;
    border-radius: 5px;
    max-width: 600px;
    margin: 1rem;

    @media (min-width: $landscape-width) {
        margin-right: 0;
        padding-right: 0;
        margin-left: 0;
        padding-left: 0;
        width: 100%;
        // max-width: 800px;
    }
    
}

.container-box-shadow {
    box-shadow: -10px -10px 0px $box-shadow-color;
}



.circle {
    width: 2rem;
    height: 2rem;
    background-image: linear-gradient(60deg, $circle-color, transparent);
    border-radius: 50%;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    text-align: left;
}

.element1 {
    grid-column: 1/3;
    text-align: left;
    padding: 1.3rem 1rem;
    border-bottom: 1px solid $gray1;
    border-image: $hline;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;

    p::first-letter {
        color: $main-color1;
    }
}

.element2 {
    padding: 0.5rem;
    border-right: 1px solid $gray1;
    border-image: $vline;
}

.element3 {
    padding: 0.5rem;
}
